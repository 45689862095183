import React from "react"
import Mailchimp from "./mailchimp"
import contactStyles from "./Contact.module.scss"

const Forms = (props) => (

    <div className={"col-lg-6 col-md-6 col-sm-12 " + contactStyles.form}>
        <h2 className="mb-4" dangerouslySetInnerHTML={{ __html: props.title }}></h2>
        <Mailchimp
            button={props.button} 
            name={props.name}
            email={props.email}
            tel={props.tel}
            msg={props.msg}
        />
    </div>

);

export default Forms;




