import React from "react"
import { ScrollTo } from "react-scroll-to";
import { StaticQuery,graphql } from "gatsby"
import {Image} from "react-bootstrap"
import footerStyles from "./Footer.module.scss"

const Footer = ({logoFooter, telephone}) => (
    <StaticQuery
        query={query}
        render={({
            site: {
                siteMetadata: {
                    defaultImage,
                    telephone
                },
            },
        }) => {
            const identity = {
                logoFooter: logoFooter || defaultImage,
                telephone : telephone
            }
            return (
                <footer className={footerStyles.footer}>
                    <div className="container text-center">
                        <Image  className={footerStyles.logoFooter} src={identity.logoFooter} alt="Dana Logo" fluid/>
                    </div>
                    <div className={footerStyles.actions}>
                        <ScrollTo>
                            {({ scroll }) => (
                                <div role = "button" tabIndex={0} className={footerStyles.scroll} onClick={() => scroll({ y: 0, smooth: true })} onKeyDown={() => scroll({ y: 0, smooth: true })}>
                                    <Image src="scroll-top.png" alt="Scroll to top" fluid width={82} />
                                </div>
                            )}
                        </ScrollTo>
                        <a className={footerStyles.call} href={identity.telephone} target='_blank'rel="noopener noreferrer">
                            <Image src="whatsapp.png" alt="whatsapp" fluid width={85} />
                        </a>
                    </div>
                </footer>
            )
        }}
    />
);

export default Footer;


const query = graphql`

    {
        site {
            siteMetadata {
                defaultImage: logoFooter
                telephone: telephone
            }
        }
    }

`
    


