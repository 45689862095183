import React from "react"
import App from "../layouts/app"




export default () => {
    return (
        <App></App>
    )
}

