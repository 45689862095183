import React from "react"
import {Row, Container} from "react-bootstrap"
import Assets from "./assets"
import boosterStyles from "./Booster.module.scss"
import Fade from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Zoom';


const Booster = (props) => (

    <Container className={"text-center p-4 "  + boosterStyles.container}>
        <Fade bottom big>
            <h1 className={boosterStyles.title} dangerouslySetInnerHTML={{ __html: props.title }}></h1>
            <h1 className={boosterStyles.subtitle} dangerouslySetInnerHTML={{ __html: props.subtitle }}></h1>
            <span dangerouslySetInnerHTML={{ __html: props.excerpt }}></span>
        </Fade>
        <Bounce>
            <Container className="mt-4">
                <Row>
                    <div className={"col " + boosterStyles.column}></div>
                    <Assets
                        excerpt={props.time}
                        image="time.png"
                        alt="On Time"
                    />
                    <Assets
                        excerpt={props.design}
                        image="lunch.png"
                        alt="Rocket Lunch"
                    />
                    <div className={"col " + boosterStyles.column}></div>
                </Row>
            </Container>
        </Bounce>
    </Container>


);

export default Booster;




