import React from "react"
import {Media} from "react-bootstrap"
import { AiFillPlusCircle } from 'react-icons/ai';

const Assets = (props) => (
    <>
        <Media as="li">
            <AiFillPlusCircle></AiFillPlusCircle>
            <Media.Body>
                <span dangerouslySetInnerHTML={{__html:props.excerpt}}></span>
            </Media.Body>
        </Media>
    </>
);

export default Assets;