import React from "react"
import { StaticQuery,graphql, Link } from "gatsby"
import {Navbar, Image} from "react-bootstrap"
import headerStyles from "./Header.module.scss"

const Header = ({logo}) => (
    <StaticQuery
        query={query}
        render={({
            site: {
                siteMetadata: {
                    defaultImage
                },
            },
        }) => {
            const identity = {
                logo: logo || defaultImage
            }
            return (
                <Navbar expand="lg" className="container">
                    <Navbar.Brand as={Link}to="/">
                        <Image src={identity.logo} className={headerStyles.logo} fluid alt="Dana Logo" />
                    </Navbar.Brand>
                </Navbar>
            )
        }}
    />
);

export default Header;


const query = graphql`
    {
        site {
            siteMetadata {
                defaultImage: logo
            }
        }
    }

`
    
