import React from "react"
import { ScrollTo } from "react-scroll-to";
import {Row, Container, Button} from "react-bootstrap"
import { FaChevronCircleRight } from 'react-icons/fa';
import Steps from "./steps"
import featureStyles from "./Feature.module.scss"
import Bounce from 'react-reveal/Zoom';

const Feature = (props) => (

    
    <div>
        <div className={"container " + featureStyles.container}>
            <Bounce big>
                <h2 className={featureStyles.title} dangerouslySetInnerHTML={{ __html: props.title }}></h2>
                <p dangerouslySetInnerHTML={{ __html: props.subtitle }}></p>
                <Container className={featureStyles.inner}>
                    <Row className="row-cols-1 row-cols-sm-2">
                        <div className="col">
                            <Steps
                                excerpt={props.items.a} 
                            />
                            <Steps
                                excerpt={props.items.b} 
                            />
                            <Steps
                                excerpt={props.items.c} 
                            />
                            <Steps
                                excerpt={props.items.d} 
                            />
                            <Steps
                                excerpt={props.items.e} 
                            />
                        </div>
                        <div className="col">
                            <Steps
                                excerpt={props.items.f} 
                            />
                            <Steps
                                excerpt={props.items.g} 
                            />
                            <Steps
                                excerpt={props.items.h} 
                            />
                            <Steps
                                excerpt={props.items.i} 
                            />
                            <Steps
                                excerpt={props.items.j} 
                            />
                        </div>
                    </Row>
                </Container>
            </Bounce>
        </div>
            <div  className={featureStyles.triangle}>
                <Bounce bottom big>
                    <Container className="text-center mb-5">
                            <h2 className={featureStyles.subtitle} dangerouslySetInnerHTML={{ __html: props.more }}></h2>
                            <p dangerouslySetInnerHTML={{ __html: props.excerpt }}></p>
                            <ScrollTo>
                                {({ scroll }) => (
                                    <Button className={featureStyles.button} tabIndex={0} onClick={() => scroll({ y: 10000, smooth: true })} onKeyDown={() => scroll({ y: 0, smooth: true })} variant="outline-dark" size="lg"><strong>{props.button} </strong>DEMO  <FaChevronCircleRight /></Button>                                
                                )}
                            </ScrollTo>
                        </Container>
                </Bounce>
            </div> 
    </div>


);

export default Feature;




