import React from "react"
import {Image} from "react-bootstrap"
import paymentStyles from "./Payment.module.scss"

const Assets = (props) => (

    <div className={"col text-center my-3"  + paymentStyles.payment}>
        <Image className={paymentStyles.image}   src={props.image} fluid alt={props.alt}></Image>
    </div>

);

export default Assets;