import React, { useState } from "react"
import PrimaryLayout from "./PrimaryLayout"
import MainBanner from "../components/main_banner/MainBanner"
import Booster from "../components/booster/booster"
import Magento from "../components/magento/magento"
import Payment from "../components/payment/payment"
import Feature from "../components/feature/feature"
import Iurco from "../components/iurco/iurco"
import Steps from "../components/steps/steps"
import Contact from "../components/contact/contact"
import appStyles from "./App.module.scss"

const data = require('../../config');

const App = (props) => {
    const [isEn, setIsEn] = useState(false);
    const toggle = () => setIsEn(!isEn);

    let lang = data;
    let payment = false;
    let activeEs = "active";
    let activeEn = "";
    
    if (isEn) {
        lang = data.en;
        payment = true;
        activeEs = "";
        activeEn = "active";
    }


    return(

        <PrimaryLayout>
            <div className={appStyles.lang}>
                <span role = "button" tabIndex={0} className={`${appStyles.es} ${activeEs}`} onClick={toggle} onKeyDown={toggle}>ES</span>
                <span className={appStyles.line}></span>
                <span role = "button" tabIndex={0} className={`${appStyles.en} ${activeEn}`} onClick={toggle} onKeyDown={toggle}>EN</span>
            </div>
            <MainBanner
                button={lang.banner.button}
                excerpt={lang.banner.excerpt}
                span={lang.banner.head.span}
                strong={lang.banner.head.strong}
                subSpan={lang.banner.p.span}
                subStrong={lang.banner.p.strong}                
            />
            <Booster
                time= {lang.dana.items.time}
                design= {lang.dana.items.design}
                excerpt= {lang.dana.excerpt}
                subtitle= {lang.dana.subtitle}
                title= {lang.dana.title}
            />
            <Magento
                subtitle= {lang.magento.subtitle}
                title= {lang.magento.title}
            />
            <Payment
                title= {lang.payment.title}
                items= {lang.payment.items} 
                plan= {lang.payment.plan}
                feature= {lang.payment.plan}
                eng={payment}
            />
            <Feature
                title= {lang.feature.title}
                subtitle= {lang.feature.subtitle}
                items= {lang.feature.items}
                more= {lang.feature.more}
                excerpt= {lang.feature.excerpt}
                button= {lang.feature.button}
            />
            <Iurco
                excerpt= {lang.iurco.excerpt}
            />
            <Steps
                title= {lang.steps.title}
                subtitle= {lang.steps.subtitle}
                items= {lang.steps}
                eng={payment}
            />
            <Contact
                button= {lang.contact.button}
                field= {lang.contact.field}
                writte= {lang.contact.writte}
                name={lang.contact.name}
                email={lang.contact.email}
                tel={lang.contact.tel}
                msg={lang.contact.msg}
            />
        </PrimaryLayout>

    )
};

export default App;

