import React from "react"
import {Image} from "react-bootstrap"
import boosterStyles from "./Magento.module.scss"

const Assets = (props) => (

    <div className="col text-center">
            <Image className={boosterStyles.image}   src={props.image} fluid alt={props.alt}></Image>
    </div>

);

export default Assets;