import React from "react"
import {Row, Container, Image, Media} from "react-bootstrap"
import { AiFillPlusCircle } from 'react-icons/ai';
import Assets from "./assets"
import stepsStyles from "./Steps.module.scss"
import Fade from 'react-reveal/Zoom';

const Steps = (props) => (
    <Container className={"text-center mb-5 " + stepsStyles.container}>
        <Fade> 
            <h2 className={stepsStyles.title} dangerouslySetInnerHTML={{ __html: props.title }}></h2>
            <p dangerouslySetInnerHTML={{ __html: props.subtitle }}></p>
            <Row className="row-cols-1 row-cols-sm-1 row-cols-md-3">
                <div className={"col " + stepsStyles.first}>
                    <div className={stepsStyles.inner}>
                        <h6 className={stepsStyles.step} dangerouslySetInnerHTML={{ __html: props.items.first.a }}></h6>
                        {(function() {
                            if (props.eng) {
                                return <Image className={stepsStyles.image} src="mobile1-en.png" fluid alt="Step one"></Image>;
                            } else {
                                return <Image className={stepsStyles.image} src="mobile1.png" fluid alt="Step one"></Image>;
                            }
                        })()}
                        <ul className="list-unstyled">
                            <Assets
                                excerpt={props.items.first.b}
                            />
                            <Assets
                                excerpt={props.items.first.c}
                            />
                            <Assets
                                excerpt={props.items.first.d}
                            />
                            <Assets
                                excerpt={props.items.first.e}
                            />
                            <Assets
                                excerpt={props.items.first.f}
                            />
                        </ul>
                    </div>
                </div>
                <div className={"col " + stepsStyles.secondMobile}>
                    <div className={stepsStyles.inner}>
                        <h6 className={stepsStyles.step} dangerouslySetInnerHTML={{ __html: props.items.second.a }}></h6>
                        {(function() {
                            if (props.eng) {
                                return <Image className={stepsStyles.image} src="mobile2-en.png" fluid alt="Step Two"></Image>;
                            } else {
                                return <Image className={stepsStyles.image} src="mobile2.png" fluid alt="Step Two"></Image>;
                            }
                        })()}
                        <ul className="list-unstyled">
                            <Assets
                                excerpt={props.items.second.b}
                            />
                            <Assets
                                excerpt={props.items.second.c}
                            />
                        </ul>
                    </div>
                </div>
                <div className={"col " + stepsStyles.third}>
                    <div className={stepsStyles.inner}>
                        <h6 className={stepsStyles.step} dangerouslySetInnerHTML={{ __html: props.items.third.a }}></h6>
                        {(function() {
                            if (props.eng) {
                                return <Image className={stepsStyles.image} src="mobile3-en.png" fluid alt="Step Three"></Image>;
                            } else {
                                return <Image className={stepsStyles.image} src="mobile3.png" fluid alt="Step Three"></Image>;
                            }
                        })()}
                        <ul className="list-unstyled">
                            <Image className={stepsStyles.image} src="fire-up.png" fluid alt="Fire Work"></Image>
                            <Media as="li">
                                <Media.Body>
                                    <span dangerouslySetInnerHTML={{ __html: props.items.third.b }}></span>
                                </Media.Body>
                                <AiFillPlusCircle></AiFillPlusCircle>
                            </Media>
                            <Image className={stepsStyles.image} src="fire-down.png" fluid alt="Fire Work"></Image>
                        </ul>
                    </div>
                </div>
            </Row>
            <Row>
                {(function() {
                    if (props.eng) {
                        return <Image className={stepsStyles.mainImage} src="steps-en.png" fluid alt="steps"></Image>;
                    } else {
                        return <Image className={stepsStyles.mainImage} src="steps.png" fluid alt="steps"></Image>;
                    }
                })()}
            </Row>
            <Row>
                <div className="col"></div>
                <div className={"col " + stepsStyles.second}>
                    <div className={stepsStyles.inner}>
                        <h6 className={stepsStyles.step} dangerouslySetInnerHTML={{ __html: props.items.second.a }}></h6>
                        <ul className="list-unstyled">
                            <Assets
                                excerpt={props.items.second.b}
                            />
                            <Assets
                                excerpt={props.items.second.c}
                            />
                        </ul>
                    </div>
                </div>
                <div className="col"></div>
            </Row>
        </Fade>
    </Container>

);

export default Steps;




