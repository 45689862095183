import React from "react"
import { ScrollTo } from "react-scroll-to";
import {Button,Image, Row} from "react-bootstrap"
import { FiChevronRight } from 'react-icons/fi';
import bannerStyles from "./MainBanner.module.scss"
import Fade from 'react-reveal/Zoom';

const MainBanner = (props) => (
    
    <div className={bannerStyles.background}>
        <div className="col-md mb-5">
            <Fade cascade>
                <h6 dangerouslySetInnerHTML={{ __html: props.excerpt }}></h6>
                <h1 className={bannerStyles.width}>
                    <strong >
                        {props.strong} <span className={bannerStyles.color}>{props.span}</span>
                    </strong>
                </h1>
                <p className={bannerStyles.width}>{props.subSpan} <br></br><strong>{props.subStrong}</strong></p>
                
                <ScrollTo>
                    {({ scroll }) => (
                        <Button className={bannerStyles.button} tabIndex={0} onClick={() => scroll({ y: 10000, smooth: true })} onKeyDown={() => scroll({ y: 0, smooth: true })} variant="outline-dark">{props.button} <FiChevronRight /></Button>
                    )}
                </ScrollTo>
            </Fade>   
        </div>
        <Fade cascade>
        <div className={"col-md text-center " + bannerStyles.details}>
            <Image src="rocket.png" alt="Rocket" fluid/>
        </div>
        </Fade>
        <div className="col-12 mt-5 text-center">
            <Row>
                <Image className={bannerStyles.mouse} src="scroll.png" alt="Scroll Mouse" fluid width="30"/>
            </Row>
            <Row>
                <Image className={bannerStyles.down} src="scroll-down.png" alt="Scroll Down" fluid width="30"/>
            </Row>
        </div>
    </div>

);

export default MainBanner;




