import React from "react"
import { FiCheckCircle } from 'react-icons/fi';
import featureStyles from "./Feature.module.scss"

const Steps = (props) => (
    <div className="text-left mb-4">
        <FiCheckCircle className={featureStyles.bullet}/>
        <span className={featureStyles.text} dangerouslySetInnerHTML={{__html:props.excerpt}}></span>
    </div>
);

export default Steps;