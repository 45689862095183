import React from "react"
import {Container} from "react-bootstrap"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import Seo from "../components/seo/Seo"


const PrimaryLayout = (props) => (
    <div>
        <Seo></Seo>
        <Header 
            toggle={props.toggle}
        />
        <Container>
            <div className="row justify-content-md-center">
                {props.children}
            </div>
        </Container>
        <Footer></Footer>
    </div>
)

export default PrimaryLayout