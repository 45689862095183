import React from "react"
import {Row, Container} from "react-bootstrap"
import Assets from "./assets"
import Forms  from "./form"
import contactStyles from "./Contact.module.scss"

const Contact = (props) => (

    <div className={"container " + contactStyles.container}>
        <div className="text-center p-1 my-5 ">
            <Container className="mt-4">
                <Row>
                    <Assets
                       fields={props.field} 
                    />
                    <Forms
                        title={props.writte} 
                        button={props.button} 
                        name={props.name}
                        email={props.email}
                        tel={props.tel}
                        msg={props.msg}
                    />
                </Row>
            </Container>
        </div>
    </div>

);

export default Contact;




