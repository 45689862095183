module.exports = {

    banner: {
        button: "Comenzar",
        excerpt: "UN PRODUCTO DE IURCO",
        head: {
            span: "empezá a vender ahora!",
            strong: "Creá tu tienda online y "
        },
        p: {
            span: "Este es el momento justo para poner en marcha a tu negocio de forma",
            strong: "rápida, simple y segura."
        }
    },
    contact: {
        button: "ENVIAR MENSAJE",
        field: {
            title:"<strong>CONTACTANOS</strong>",
            whatsapp:"Mandanos un whatsapp"
        },
        writte: "<strong>ESCRIBINOS</strong>",
        name: "Nombre",
        email: "Email",
        tel: "Teléfono",
        msg: "Contanos qué tenés en mente ;)"

    },
    dana: {
        items: {
            time: "<strong>En solo 7 días</strong><br> Tu plataforma online",
            design: "<strong>Diseño </strong>que <br>genera ventas"
        },
        excerpt: "Con este booster de comercio electrónico vas a poder tener tu plataforma <br> de venta online funcionando en una semana",
        subtitle: "tu e-commerce booster",
        title: "DANA"
    },
    feature: {
        title:"Características de DANA",
        subtitle:"Usá esta plataforma y descubrí un administrador de contenido sencillo que te permite ofrecer tus productos, administrar tu catálogo y crear promociones.",
        items: {
            a: "Plataforma de eCommerce <strong>Magento Open Source.</strong>",
            b: "<strong>Mercado Pago</strong> para cobrar en tu tienda.",
            c: "<strong>Conocé que pasa con tu tienda</strong> (Google Analytics y Google Tag Manager)",
            d: "Configura <strong>promociones</strong> y genera <strong>cupones de descuento.<strong>",
            e: "<strong>Envia los pedidos</strong> al domicilio de tu cliente.",
            f: "Gestiona tu catálogo desde un excel.",
            g: "Crea campañas de Email Marketing.",
            h: "Optimización para aparecer en las búsquedas de Google (SEO).",
            i: "Velocidad de carga y buena visualización en Celulares y Tablets.",
            j: "Diseño actualizado a las últimas tendencias de Usabilidad."
        },
        more:"Conoce más",
        excerpt:"Contactate con nosotros y solicita tu demo. <br></br><strong>¡Podemos ayudarte!</strong>",
        button:"Pedir",
    },
    iurco: {
        excerpt: "Somos una agencia boutique, sin procesos jurásicos y con la agilidad del mundo actual. En un mundo tan cambiante, las marcas necesitan moverse más rápido que nunca. Necesitan soluciones inteligentes e integradas desarrolladas con velocidad, escalabilidad y, sobre todo, calidad. Nuestra solución es simple y singular. Una sola agencia que trabaje de manera eficaz para poder entregar productos por encima de la media."
    },
    magento: {
        title: "Tu tienda con la plataforma <br> Nº 1 del mercado",
        subtitle: "MARCAS QUE USAN MAGENTO"
    },
    payment: {
        title: "<strong>DANA,</strong> además, <strong>piensa en tu bolsillo</strong>",
        items: {
            a: "¿Qué pasaría si te decimos que podes tener <strong>tu tienda online, solo tuya y sin compartir con nadie tu servidor?</strong>",
            b: "¿Y si además podes pagar por todo esto en <strong>10 cuotas sin interés con Mercado Pago</strong> y solo con un <strong>abono mensual sin comisiones?</strong>",
            c: "<strong>INCREIBLE ¿NO?</strong>"
        },
        plan: {
            pro: {
                title:"<b>Plan Pro</b>",
                price:"PROXIMAMENTE",
                items: 
                    {
                        a: "<strong>Magento Open Source</strong>",
                        b: "<strong>Servidor Dedicado</strong>, no compartis tu tienda con nadie",
                        c: "<strong>Mercago Pago</strong> integrado",
                        d: "<strong>Andreani</strong> y Tabla de Costos por Código Postal",
                        e: "<strong>Un backend, multiples dominios</strong>",
                        f: "<strong>Multi moneda - Multi idioma</strong>",
                        g: "PWA Theme",
                        h: "<strong>SEO</strong> Starter pack",
                        i: "Gestión de <strong>Ads</strong> ",
                        j: "<strong>Promociones bancarias</strong> por Banco y",
                    },
                button:"<strong>ACTIVAR </strong>Plan Pro",
            },
            starter: {
                title: "<b>Plan Starter</b>",
                price:"<span>START UP</span><span>12 cuotas de</span><span><em>AR$</em> 12.500</span><span>Pagando con</span>",
                mensual:"<span>Abono mensual de</span><span><em>AR$</em> 10.000</span>",
                items: {
                    a: "<strong>Magento Open Source</strong>",
                    b: "<strong>Servidor Dedicado</strong>, no compartis tu tienda con nadie",
                    c: "<strong>Template</strong> light para mejor perfomance",
                    d: "<strong>Mercago Pago</strong> integrado",
                    e: "<strong>Andreani</strong> y Tabla de Costos por Código Postal",
                    f: "<strong>GTM</strong> y <strong>Google</strong>  Analytics instalados"
                },
                button: "<strong>ACTIVAR </strong>Plan Starter",
            }
        }
    },
    steps: {
        title:"¿Empezamos?",
        subtitle:"Usá esta plataforma y descubrí un administrador de contenido sencillo que te permite ofrecer tus productos, administrar tu catálogo y crear promociones.",
        first: {
                a:"PREPARADOS",
                b: "<strong>Reunión</strong> de conocimiento.",
                c: "<strong>Arma un Excel con todos los productos</strong> que queres vender. No te olvides de las <strong>fotos</strong> y la <strong>descripción</strong> de cada uno.",
                d: "Elección de <strong>Diseño.</strong>",
                e: "Aplicar los <strong>colores</strong> y <strong>logo</strong> de tu Marca.",
                f: "Elegir el <strong>dominio</strong> para tu sitio."
            },
        second: {
            a:"LISTOS",
            b: "Activamos los Métodos de Pago y los Métodos de Envio.",
            c: "Cargamos tu catálogo."
        },
        third: {
            a:"YA!",
            b: "<strong>¡SALIMOS A VENDER!</strong>"
        }
    },
    //ENG
    en:{
        banner: {
            button: "Start",
            excerpt: "A IURCO PRODUCT",
            head: {
                span: "begin selling your products right now!",
                strong: "Create your own online store and "
            },
            p: {
                span: "This is the exact moment to set in motion your business in a fast, simple and secure way.",
                strong: " "
            }
        },
        contact: {
            button: "SENT MESSAGE",
            field: {
                title:"<strong>CONTACT US</strong>",
                whatsapp:"Send us a whatsapp"
            },
            writte: "<strong>WRITTE US</strong>",
            name: "NAME",
            email: "Email",
            tel: "Tel",
            msg: "tell us what`s on your mind ;)"
        },
        dana: {
            items: {
                time: "Online Platform<br><strong>in 7 days</strong>",
                design: "<strong>Design </strong>that <br>generate sells"
            },
            excerpt: "With this e-commerce booster  your sales platform will be working in a one week period.",
            subtitle: "your e-commerce booster",
            title: "DANA"
        },
        feature: {
            title:"DANA Characteristics",
            subtitle:"Use this platform and discover a simple system administrator that allows you to offer your products, administrate your catalog and create special offers.",
            items: {
                a: "E-commerce  <strong>Magento Open Source Platform</strong>",
                b: "<strong>PayPal or Stripe</strong> to cash in your sales",
                c: "<strong>Be aware about your store statement </strong> (Google Analytics y Google Tag Manager)",
                d: "Set <strong>special Offers</strong> and generate <strong>discount coupons<strong>",
                e: "<strong>Home delivery</strong>",
                f: "Run your catalog from Excel.",
                g: "Create e-mail marketing campaigns",
                h: "Search Engine Optimization",
                i: "Loading speed and good visualization from Cellphones and tablets",
                j: "Design updated to the latest usability trends."
            },
            more:"Learn more",
            excerpt:"Get in touch with us and ask for your demo <br></br><strong> We can help you!</strong>",
            button:"Ask for",
        },
        iurco: {
            excerpt: "We are a boutique agency, with no jurassic processes and with the agility that the world we live in requires. In this changing world (aconsejaría no repetir world)brands need to move faster and safer than ever. Brands need intelligent and integrated solutions developed with speed, scalability and, above all, quality. Our solution is singular and simple. A single agency that works effectively to deliver above-average products."
        },
        magento: {
            title: "Your Store with the <br> number 1 platform  in the market",
            subtitle: "Brands using Magento"
        },
        payment: {
            title: "<strong>DANA,</strong> also takes into account <strong>your finances or business economics</strong>",
            items: {
                a: "What would happen if we tell you that you could get  <strong>your online store as a sole owner and without sharing your server with anyone?</strong>",
                b: "And if we also tell you that you could pay for all of this in  <strong>only one pay, a monthly fee and no commision?</strong>",
                c: "<strong>Incredible, Right?</strong>"
            },
            plan: {
                starter: {
                    title: "<b>Plan DANA</b>",
                    price:"<span> </span><span>One time Fee </span><span><em>US$</em> 2.500</span><span>Pay with</span>",
                    mensual:"<span>Monthly payment</span><span><em>US$</em> 100</span>",
                    items: {
                        a: "<strong>Magento Open Source</strong>",
                        b: "<strong>Dedicated Server</strong>",
                        c: "Light <strong>Template</strong> improve performance",
                        d: " Payment Gateway: <strong>Paypal y Stripe</strong>",
                        e: "Shipping Methods: <strong>FedEX, UPS, Local Post with Oficial Magento module,</strong> and Matrix Rate.",
                        f: "<strong>GTM</strong> y <strong>Google Analytics</strong>"
                    },
                    button: "<strong>ACTIVATE</strong>",
                }
            }
        },
        steps: {
            title:"Shall we start?",
            subtitle:"Use this platform and discover a simple content manager that allows you to offer your products, manage your catalog and create sales offers.",
            first: {
                    a:"READY",
                    b: "Knowledge <strong>meeting</strong>",
                    c: "<strong>Create an Excel with all the products</strong>you want to sell. Do not forget the <strong>photos</strong> and the <strong>description</strong> of each one.",
                    d: "<strong>Design</strong> choice.",
                    e: "Apply <strong>the colors</strong> and <strong>logo</strong> of your Brand.",
                    f: "Choose the <strong>domain</strong> for your site."
                },
            second: {
                a:"Thats all",
                b: "We activate the Payment and Shipping Methods.",
                c: "We load your catalog"
            },
            third: {
                a:"GO",
                b: "<strong>We go out to sell!</strong>"
            }
        }
    }
    
};
  