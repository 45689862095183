import React from "react"
import {Row, Container, Col, Image, ListGroup, Button} from "react-bootstrap"
import { ScrollTo } from "react-scroll-to";
import { FaBullseye } from 'react-icons/fa';
import Steps from "./steps"
import Assets from "./assets"
import paymentStyles from "./Payment.module.scss"
import Slide from 'react-reveal/Zoom';

const Payment = (props) => (

    <div className={"container " + paymentStyles.container}>
        <Slide bottom big>
            <h1 className={paymentStyles.title} dangerouslySetInnerHTML={{ __html: props.title }}></h1>
        </Slide>
        <Slide bottom big>
            <Container className="mt-4">
                    <Steps
                        excerpt={props.items.a}
                    />
                    <Steps
                        excerpt={props.items.b}
                    />
                    <Steps
                        excerpt={props.items.c}
                    />
            </Container>
        </Slide>
        <Slide bottom big>
            <Container className="mt-4">
                <Row className="mt-4 row-cols-3 row-cols-sm-3 row-cols-md-3 row-cols-lg-3 px-5">
                {(function() {
                    if (props.eng) {
                        return 
                    } else {
                        return  <> 
                                    <Assets
                                        image="mp.png"
                                        alt="Mercado Pago"
                                    />
                                    <Assets
                                        image="cards.png"
                                        alt="Credit Card"
                                    />
                                    <Assets
                                        image="server.png"
                                        alt="Server"
                                    />
                                </> 
                    }
                })()}
                </Row>
            </Container>
        </Slide>
        <Slide bottom big>
            <Container className="my-5 text-center px-3">
                {(function() {
                    if (props.eng) {
                        return <Row>
                                    <Col className="my-2" md={{ span: 6, offset: 3 }}>
                                        <div className={paymentStyles.plan}>
                                            <h2 dangerouslySetInnerHTML={{ __html: props.plan.starter.title }}></h2>
                                            <div className={paymentStyles.divider}>
                                            <div dangerouslySetInnerHTML={{ __html: props.plan.starter.price }}></div>
                                                <Image className={paymentStyles.image}   src="stripe.png" fluid alt="Mercado Pago"></Image>
                                            </div>
                                            <div className={paymentStyles.divide} dangerouslySetInnerHTML={{ __html: props.plan.starter.mensual }}></div>
                                            <ListGroup className="text-left my-4">
                                                <ListGroup.Item><FaBullseye className={paymentStyles.list}/><p dangerouslySetInnerHTML={{ __html: props.plan.starter.items.a }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.list}/><p dangerouslySetInnerHTML={{ __html: props.plan.starter.items.b }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.list}/><p dangerouslySetInnerHTML={{ __html: props.plan.starter.items.c }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.list}/><p dangerouslySetInnerHTML={{ __html: props.plan.starter.items.d }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.list}/><p dangerouslySetInnerHTML={{ __html: props.plan.starter.items.e }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.list}/><p dangerouslySetInnerHTML={{ __html: props.plan.starter.items.f }}></p></ListGroup.Item>
                                            </ListGroup>
                                            <ScrollTo>
                                                {({ scroll }) => (
                                                    <Button tabIndex={0} onClick={() => scroll({ y: 10000, smooth: true })} onKeyDown={() => scroll({ y: 0, smooth: true })} className={paymentStyles.button} size="lg" dangerouslySetInnerHTML={{ __html: props.plan.starter.button }}></Button>                                
                                                )}
                                            </ScrollTo>
                                        </div>
                                    </Col>
                                </Row>;
                    } else {
                        return <Row xs={1} md={2}>
                                    <Col className="my-2">
                                        <div className={paymentStyles.plan}>
                                            <h2 dangerouslySetInnerHTML={{ __html: props.plan.starter.title }}></h2>
                                            <div className={paymentStyles.divider}>
                                            <div dangerouslySetInnerHTML={{ __html: props.plan.starter.price }}></div>
                                                <Image className={paymentStyles.image}   src="mp-a.png" fluid alt="Mercado Pago"></Image>
                                            </div>
                                            <div className={paymentStyles.divide} dangerouslySetInnerHTML={{ __html: props.plan.starter.mensual }}></div>
                                            <ListGroup className="text-left my-4">
                                                <ListGroup.Item><FaBullseye className={paymentStyles.list}/><p dangerouslySetInnerHTML={{ __html: props.plan.starter.items.a }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.list}/><p dangerouslySetInnerHTML={{ __html: props.plan.starter.items.b }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.list}/><p dangerouslySetInnerHTML={{ __html: props.plan.starter.items.c }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.list}/><p dangerouslySetInnerHTML={{ __html: props.plan.starter.items.d }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.list}/><p dangerouslySetInnerHTML={{ __html: props.plan.starter.items.e }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.list}/><p dangerouslySetInnerHTML={{ __html: props.plan.starter.items.f }}></p></ListGroup.Item>
                                            </ListGroup>
                                            <ScrollTo>
                                                {({ scroll }) => (
                                                    <Button tabIndex={0} onClick={() => scroll({ y: 10000, smooth: true })} onKeyDown={() => scroll({ y: 0, smooth: true })} className={paymentStyles.button} size="lg" dangerouslySetInnerHTML={{ __html: props.plan.starter.button }}></Button>                                
                                                )}
                                            </ScrollTo>
                                        </div>
                                    </Col>
                                    <Col className="my-2">
                                        <div className={paymentStyles.plan}>
                                            <h2 dangerouslySetInnerHTML={{ __html: props.plan.pro.title }}></h2>
                                            <div className={paymentStyles.divider}>
                                                <span  className="text-dark" dangerouslySetInnerHTML={{ __html: props.plan.pro.price }}></span>
                                            </div>
                                            <div className={paymentStyles.divide}>
                                                <Image className="p-2"   src="medios.png" fluid alt="Medios de Pago: Todo Pago, Prisma, Mercado Pago, OCA, Correo Argentino y Glovo"></Image>
                                            </div>
                                            <ListGroup className="text-left my-4">
                                                <ListGroup.Item><FaBullseye className={paymentStyles.lista}/><p dangerouslySetInnerHTML={{ __html: props.plan.pro.items.a }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.lista}/><p dangerouslySetInnerHTML={{ __html: props.plan.pro.items.b }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.lista}/><p dangerouslySetInnerHTML={{ __html: props.plan.pro.items.c }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.lista}/><p dangerouslySetInnerHTML={{ __html: props.plan.pro.items.d }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.lista}/><p dangerouslySetInnerHTML={{ __html: props.plan.pro.items.e }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.lista}/><p dangerouslySetInnerHTML={{ __html: props.plan.pro.items.f }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.lista}/><p dangerouslySetInnerHTML={{ __html: props.plan.pro.items.g }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.lista}/><p dangerouslySetInnerHTML={{ __html: props.plan.pro.items.h }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.lista}/><p dangerouslySetInnerHTML={{ __html: props.plan.pro.items.i }}></p></ListGroup.Item>
                                                <ListGroup.Item><FaBullseye className={paymentStyles.lista}/>
                                                    <p dangerouslySetInnerHTML={{ __html: props.plan.pro.items.j }}></p>
                                                    <Image className={paymentStyles.tp}   src="ahora12.png" fluid alt="Ahora 12"></Image>
                                                    <Image className={paymentStyles.tp}   src="ahora18.png" fluid alt="Ahora 18"></Image>
                                                </ListGroup.Item>
                                            </ListGroup>
                                            <Button className={paymentStyles.buttonoff} size="lg" disabled dangerouslySetInnerHTML={{ __html: props.plan.pro.button }}></Button>
                                        </div>
                                    </Col>
                                </Row>;
                    }
                })()}
            </Container>
        </Slide>
    </div>


);

export default Payment;




