import React from "react"
import {Image} from "react-bootstrap"
import iurcoStyles from "./Iurco.module.scss"

const Assets = (props) => (

    <div className="col text-center">
        <Image className={iurcoStyles.image}   src={props.image} fluid alt={props.alt}></Image>
    </div>

);

export default Assets;