import React from "react"
import {Row, Container, Image} from "react-bootstrap"
import Assets from "./assets"
import magentoStyles from "./Magento.module.scss"
import Fade from 'react-reveal/Zoom';

const Magento = (props) => (

    <div className={"container " + magentoStyles.container}>
        <Fade big>
            <h3 className={magentoStyles.title} dangerouslySetInnerHTML={{ __html: props.title }}></h3>
            <Container className="mt-4">
                <Row>
                    <div className={"col " + magentoStyles.column}></div>
                    <div className="col">
                        <Image className={magentoStyles.magento}   src="magento.png" fluid alt="Magento Adobe Company"></Image>
                    </div>
                    <div className="col">
                        <Image className={magentoStyles.magento}   src="partner.png" fluid alt="Magento Solution Partner"></Image>
                    </div>
                    <div className={"col " + magentoStyles.column}></div>
                </Row>
            </Container>
            <Container className="mt-4">
                <span className={magentoStyles.brands} dangerouslySetInnerHTML={{ __html: props.subtitle }}></span>
                <Row className="mt-4 row-cols-2 row-cols-sm-2 row-cols-md-4">
                    <Assets
                        image="coca-cola.png"
                        alt="Coca-Cola"
                    />
                    <Assets
                        image="carrefour-magento.png"
                        alt="Carrefour"
                    />
                    <Assets
                        image="nike.png"
                        alt="nike"
                    />
                    <Assets
                        image="Motorola-magento.png"
                        alt="Motorola"
                    />
                </Row>
                <Row className="mt-4 row-cols-2 row-cols-sm-2 row-cols-md-4">
                    <Assets
                        image="hp.png"
                        alt="hp"
                    />
                    <Assets
                        image="noblex-magento.png"
                        alt="Noblex"
                    />
                    <Assets
                        image="movistar-magento.png"
                        alt="Movistar"
                    />
                    <Assets
                        image="gps-magento.png"
                        alt="GPS Farma"
                    />
                </Row>
            </Container>
        </Fade>
    </div>


);

export default Magento;




