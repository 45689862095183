import React from "react"
import {Row, Col} from "react-bootstrap"
import { FaBullseye } from 'react-icons/fa';
import paymentStyles from "./Payment.module.scss"

const Steps = (props) => (
    <Row className={paymentStyles.background}>
        <Col className="text-left">
            <FaBullseye className={paymentStyles.bullet}/>
            <span dangerouslySetInnerHTML={{__html:props.excerpt}}></span>
        </Col>
    </Row>
);

export default Steps;