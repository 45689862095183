import React from "react"
import {Image, Media} from "react-bootstrap"
import contactStyles from "./Contact.module.scss"

const Assets = (props) => (
    <div className={"col-lg-6 col-md-6 col-sm-12 "  + contactStyles.contact}>
        <h2 className="mb-4" dangerouslySetInnerHTML={{ __html: props.fields.title }}></h2>
        <ul className="list-unstyled">
            <Media as="li" className="mb-2">
                <Image className={contactStyles.imagesMobile} src="mail.png" fluid alt="Email"></Image>
                <Media.Body>
                    <a href="mailto:info@iurco.com">info@iurco.com</a>
                </Media.Body>
                <Image className={contactStyles.image} src="mail.png" fluid alt="Email"></Image>
            </Media>
            <Media as="li" className="mb-2">
                <Image className={contactStyles.imagesMobile} src="tel.png" fluid alt="Whatsapp"></Image>
                <Media.Body>
                    <span dangerouslySetInnerHTML={{ __html: props.fields.whatsapp }}></span>
                </Media.Body>
                <Image className={contactStyles.image} src="tel.png" fluid alt="Whatsapp"></Image>
            </Media>
            <Media as="li" className="mb-2">
                <Image className={contactStyles.imagesMobile} src="local.png" fluid alt="Marker"></Image>
                <Media.Body>
                    <span>Cátulo Castillo 2580, Ciudad de Buenos Aires, Buenos Aires - Argentina</span>
                </Media.Body>
                <Image className={contactStyles.image} src="local.png" fluid alt="Marker"></Image>
            </Media>
        </ul>
    </div>
);

export default Assets;