import React, { useState } from "react"
import {Form, Button} from "react-bootstrap"
import addToMailchimp from "gatsby-plugin-mailchimp"
import contactStyles from "./Contact.module.scss"



function Mailchimp(props) {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [msj, setMsj] = useState('');
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
  
    const handleSubmit = async event => {
      event.preventDefault();
      // Mailchimp always responds with status code 200, accompanied by a string indicating the result of the response.
      const { result, msg } = await addToMailchimp(email,{FNAME:name,PHONE:phone,MMERGE2:msj});
      result === 'success'  && setEmail('') && setName('') && setPhone('') && setMsj('');
      // Removes the HTML returned in some response messages in case of error
      setMessage(msg.split('<')[0]);
      setStatus(result);
      console.log(result, msg);
    };
  
    const handleEmail = event => {
        setEmail(event.target.value);
    }
    const handleName = event => {
        setName(event.target.value);
    }
    const handlePhone = event => {
        setPhone(event.target.value);
    }
    const handleMsj = event => {
        setMsj(event.target.value);
    }
  
    return (
        <Form>
            <Form.Group controlId="name">
                <Form.Label>Nombre</Form.Label>
                <Form.Control onChange={handleName} value={name} type="text" placeholder={props.name}/>
            </Form.Group>
            <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email"onChange={handleEmail} value={email} placeholder={props.email} />
            </Form.Group>
            <Form.Group controlId="nro">
                <Form.Label>Tel</Form.Label>
                <Form.Control type="number" onChange={handlePhone} value={phone} placeholder={props.tel} />
            </Form.Group>
            <Form.Group controlId="msg">
                <Form.Label>Nombre</Form.Label>
                <Form.Control as="textarea" onChange={handleMsj} value={msj} placeholder={props.msg} rows="3" />
            </Form.Group>
                <span status={status}>{message}</span>
            <Button className={contactStyles.button} variant="outline-dark" size="sm" block type="submit" onClick={handleSubmit}><strong>{props.button}</strong></Button>
        </Form>
    );
  }
  
  export default Mailchimp;


