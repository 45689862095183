import React from "react"
import {Row, Container, Image} from "react-bootstrap"
import Assets from "./assets"
import iurcoStyles from "./Iurco.module.scss"
import Slide from 'react-reveal/Zoom';

const Iurco = (props) => (
    <>
        <div className={"container " + iurcoStyles.container}>
            <Container>
                <Slide>
                    <Image className={iurcoStyles.iurco}   src="iurco.png" fluid alt="Iurco"></Image>
                </Slide>
                <Slide>
                    <span className={iurcoStyles.brands}>{props.excerpt}</span>
                    <Row className="mt-4 row-cols-2 row-cols-sm-3 row-cols-md-6">
                        <Assets
                            image="Motorola.png"
                            alt="Motorola"
                        />
                        <Assets
                            image="vodafone.png"
                            alt="Vodafone"
                        />
                        <Assets
                            image="movistar.png"
                            alt="Movistar"
                        />
                        <Assets
                            image="gps.png"
                            alt="GPS Farma"
                        />
                        <Assets
                            image="ns.png"
                            alt="Newsan"
                        />
                        <Assets
                            image="noblex.png"
                            alt="Noblex"
                        />
                    </Row>
                    <Row className="row-cols-2 row-cols-sm-3 row-cols-md-5">
                        <Assets
                            image="unilever.png"
                            alt="Unilever"
                        />
                        <Assets
                            image="quaker.png"
                            alt="Quaker"
                        />
                        <Assets
                            image="atma.png"
                            alt="Atma"
                        />
                        <Assets
                            image="exo.png"
                            alt="Exo"
                        />
                        <Assets
                            image="red-bull.png"
                            alt="Red Bull"
                        />
                    </Row>
                </Slide>
            </Container>
        </div>
    </>
);

export default Iurco;




